<template>
    <div class="form-input-radio-group">
        <slot />
    </div>
</template>

<style lang="scss">
.form-input-radio-group {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    margin-left: -20px;
}
</style>

<script setup>
import Birthday from 'assets/icons/birthday.svg';
import Eye from 'assets/icons/eye.svg';
import EyeClosed from 'assets/icons/eye-closed.svg';

const props = defineProps({
    config: {
        type: Object,
        default: () => {
        }
    }
});

// Toggle password
const showPassword = ref(false);

const isPassword = computed(() => props.config?.fieldType === 'password');

const togglePassword = () => {
    showPassword.value = !showPassword.value
    input.value.type = showPassword.value ? 'text' : 'password'
}

// Usefield
const { value, errorMessage, meta, resetField } = useField(() =>
    props.config?.name, props.config?.validationRules
);

// Show error + success classes in case fields have validation rules
const _requiresValidation = ref(props.config?.validationRules);
const requiresValidation = computed(() => _requiresValidation.value !== undefined);
const isValid = computed(() => meta.valid && meta.dirty && requiresValidation.value);

const formFieldClass = computed(() => {
    return {
        'form-error': !meta.valid && meta.dirty && requiresValidation.value,
        'form-success': isValid.value
    }
})

// Change initial value with dynamic data of input field
resetField({
    value: props.config?.val
});

// Use watcher to update data with dynamic values (seems to be necessary...?)
watch(() => props.config?.val, () => {
    resetField({
        value: props.config?.val
    });

    _requiresValidation.value = props.config?.validationRules
});

// Show datepicker
const input = ref(null);
const isDate = computed(() => props.config?.fieldType === 'date');
const clickEvent = computed(() => isDate.value ? 'click' : null);

const showDatePicker = () => {
    input.value.focus();

    if (typeof HTMLInputElement.prototype.showPicker === 'function') {
        input.value.showPicker();
    }
}
</script>

<template>
    <div class="form-input-text-wrapper" :class="formFieldClass">
        <label class="form-input-text-label" :class="config.required ? 'required' : ''">
            {{ config.label }}
        </label>

        <div class="form-input-text-container">
            <input
                ref="input"
                v-model="value"
                class="form-input-text"
                :readonly="config.readonly"
                :required="config.required"
                :name="config.name"
                :type="config.fieldType"
                :placeholder="config.placeholder"
                @[clickEvent]="showDatePicker"
            >

            <span
                v-if="isPassword && config.includeToggle"
                class="form-input-password-toggle"
                :class="showPassword ? 'show-password' : ''"
                @click="togglePassword"
            >
                <EyeClosed class="hide-password-icon" />
                <Eye class="show-password-icon" />
            </span>

            <span v-if="isDate" class="form-input-date-icon" :class="formFieldClass" @click="showDatePicker">
                <Birthday />
            </span>

            <LoadingSpinner v-if="config.pending" class="form-loader" />
        </div>

        <FormFieldMessage
            :msg-config="{
                errorMessage: errorMessage,
                error: errorMessage && meta.dirty,
            }"
        />

        <span v-if="config.helperText" class="helper-text">
            {{ config.helperText }}
        </span>
    </div>
</template>

<style lang="scss">
.form-input-text-wrapper {
    margin: 0 0 20px;
    position: relative;
}

.form-input-text-container {
    position: relative;
}

.form-input-text-label {
    @include form-label;
}

.form-input-password-toggle,
.form-input-date-icon {
    @include form-icon;
}

// Safari only hack.. weird & ugly
@supports (-webkit-hyphens:none) {
    .form-input-date-icon {
        pointer-events: none;
        cursor: auto;
    }
}

.form-input-text {
    @include inputfield;

    .form-error & {
        background: $form-input-text-error-bg;
        border: $form-input-text-error-border 2px solid;
    }
}

.helper-text {
    @include helper-text;
}

.show-password-icon {
    opacity: 1;
    position: static;
    pointer-events: all;
    transition: opacity 400ms ease;

    .show-password & {
        opacity: 0;
        position: fixed;
        pointer-events: none;
    }
}

.hide-password-icon {
    opacity: 0;
    position: fixed;
    pointer-events: none;
    transition: opacity 400ms ease;

    .show-password & {
        opacity: 1;
        position: static;
        pointer-events: all;
    }
}

</style>

<template>
    <div class="form-section">
        <slot />
    </div>
</template>

<style lang="scss">
.form-section {
    margin-bottom: 20px;
    overflow: hidden;
}
</style>

<script setup>
const props = defineProps({
    config: {
        type: Object,
        default: () => {
        }
    }
});

//  Creates a form field with its validation state, you will use this inside your custom input components.
const { value, errorMessage, meta, resetField } = useField(() =>
    props.config?.name, props.config?.validationRules
);

// Show error + success classes in case fields have validation rules
const _requiresValidation = ref(props.config?.validationRules);
const requiresValidation = computed(() => _requiresValidation.value !== undefined);

const formFieldClass = computed(() => {
    return {
        'form-error': !meta.valid && meta.dirty && requiresValidation.value,
        'form-success': meta.valid && meta.dirty && requiresValidation.value
    }
})

// Change initial value with dynamic data of input field
resetField({
    value: props.config?.val
});

// Use watcher to update data with dynamic values (seems to be necessary...?)
watch(() => props.config?.val, () => {
    resetField({
        value: props.config?.val
    });

    _requiresValidation.value = props.config?.validationRules
});
</script>

<template>
    <div class="form-input-radio-wrapper" :class="formFieldClass">
        <input
            :id="config.id"
            v-model="value"
            class="form-input-radio"
            :type="config.fieldType"
            :value="config.value"
            :required="config.required"
            :name="config.name"
        >
        <label class="form-input-radio-label-wrapper" :class="config.required" :for="config?.id">
            <span class="form-input-radio-label">{{ config.label }}</span>
        </label>

        <FormFieldMessage
            :msg-config="{
                errorMessage:errorMessage,
                error:errorMessage && meta.dirty
            }"
        />
    </div>
</template>

<style lang="scss">
.form-input-radio-wrapper {
    position: relative;
    margin: 0 0 20px 20px;
}

.form-input-radio {
    opacity: 0;
    position: absolute;
    z-index: -1;

    &:checked {
        + label {
            &::after {
                opacity: 1;
            }
        }
    }
}

.form-input-radio-label-wrapper {
    min-height: 28px;
    margin: 0;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 100%;

    &::before {
        content: '';
        width: 28px;
        height: 28px;
        border: $form-field-radio-label-border 2px solid;
        background: $form-field-radio-label-bg;
        border-radius: 100%;
        position: absolute;
        display: block;
        top: 0;
        left: 0;
    }

    &::after {
        content: '';
        width: 18px;
        height: 18px;
        border-radius: 100%;
        background: $form-field-radio-label-checked-bg;
        display: block;
        position: absolute;
        top: 5px;
        left: 5px;
        opacity: 0;
        transition: opacity 400ms ease;
    }
}

.form-input-radio-label {
    @include body2-medium;

    padding-left: 34px;
    display: block;
}
</style>
